import { Inject, Injectable } from '@angular/core';
import { Location } from '@angular/common';
import { ActivatedRoute, Router, NavigationEnd } from '@angular/router';
import { Subject } from 'rxjs';
import { ENVIRONMENT_CONFIG } from '@parashift/shared/environment-config';


@Injectable({
  providedIn: 'root'
})
export class HistoryService {
  private currentUrlSubject = new Subject<string>();
  currentUrl$ = this.currentUrlSubject.asObservable();
  currentHost: string;
  idNode: string;

  requestedUrl: string;
  urlAfterRedirect: string;
  history: string;

  constructor(
    private router: Router,
    private ngLocation: Location,
    @Inject(ENVIRONMENT_CONFIG) private environment: any
  ) {
    this.currentHost = window.location.origin;
    this.idNode = this.environment.id_node;
    const path = this.ngLocation.path().split('?');
    this.requestedUrl = path[0];
    this.initHistory();
  }

  initHistory() {
    this.router.events.subscribe(event => {
      if (event instanceof NavigationEnd) {
        this.history = this.urlAfterRedirect;
        this.urlAfterRedirect = event.urlAfterRedirects;
        this.currentUrlSubject.next(event.urlAfterRedirects);
      }
    });
  }

  gotoPreviousPage(fallback: string, relative?: ActivatedRoute) {
    const navigationExtras = relative ? { relativeTo: relative } : {};

    if (this.history) {
      this.router.navigateByUrl(this.history);
    } else if (fallback) {
      this.router.navigate([fallback], navigationExtras);
    }
  }
}
